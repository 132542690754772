import React from "react";

import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

const Metadata: React.FC<{
    title?: string;
    desc: string;
    banner?: string;
    pathname?: string;
    article?: string;
    meta?: { firstPublicationDate?: string; lastPublicationDate?: string };
}> = ({ title, desc, banner, pathname, article, meta }) => {
    return (
        <StaticQuery
            query={`${query}`}
            render={({
                site: {
                    buildTime,
                    siteMetadata: {
                        siteUrl,
                        defaultTitle,
                        defaultDescription,
                        defaultBanner,
                        headline,
                        siteLanguage,
                        author,
                    },
                },
            }) => {
                const seo = {
                    title: title ? `Open Weaves | ${title}` : defaultTitle,
                    description: desc || defaultDescription,
                    image: `${siteUrl}${banner || defaultBanner}`,
                    url: `${siteUrl}${pathname || ""}`,
                };

                // schema.org in JSONLD format
                // https://developers.google.com/search/docs/guides/intro-structured-data
                // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

                const schemaOrgWebPage = {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    url: siteUrl,
                    headline,
                    inLanguage: siteLanguage,
                    mainEntityOfPage: siteUrl,
                    description: defaultDescription,
                    name: defaultTitle,
                    author: {
                        "@type": "Person",
                        name: author,
                    },
                    copyrightHolder: {
                        "@type": "Person",
                        name: author,
                    },
                    copyrightYear: "2021",
                    creator: {
                        "@type": "Person",
                        name: author,
                    },
                    publisher: {
                        "@type": "Person",
                        name: author,
                    },
                    datePublished: "2021-06-18T20:00:00+10:00",
                    dateModified: buildTime,
                    image: {
                        "@type": "ImageObject",
                        url: `${siteUrl}${defaultBanner}`,
                    },
                };

                // Initial breadcrumb list

                const itemListElement = [
                    {
                        "@type": "ListItem",
                        item: {
                            "@id": siteUrl,
                            name: "Homepage",
                        },
                        position: 1,
                    },
                ];

                let schemaArticle: any = null;

                if (article) {
                    schemaArticle = {
                        "@context": "http://schema.org",
                        "@type": "Article",
                        author: {
                            "@type": "Person",
                            name: author,
                        },
                        copyrightHolder: {
                            "@type": "Person",
                            name: author,
                        },
                        copyrightYear: "2021",
                        creator: {
                            "@type": "Person",
                            name: author,
                        },
                        publisher: {
                            "@type": "Organization",
                            name: author,
                            logo: {
                                "@type": "ImageObject",
                                url: `${siteUrl}${defaultBanner}`,
                            },
                        },
                        datePublished: meta?.firstPublicationDate,
                        dateModified: meta?.lastPublicationDate,
                        description: seo.description,
                        headline: seo.title,
                        inLanguage: "en",
                        url: seo.url,
                        name: seo.title,
                        image: {
                            "@type": "ImageObject",
                            url: seo.image,
                        },
                        mainEntityOfPage: seo.url,
                    };

                    // Push current blogpost into breadcrumb list
                    itemListElement.push({
                        "@type": "ListItem",
                        item: {
                            "@id": seo.url,
                            name: seo.title,
                        },
                        position: 2,
                    });
                }

                const breadcrumb = {
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    description: "Breadcrumbs list",
                    name: "Breadcrumbs",
                    itemListElement,
                };

                return (
                    <>
                        <Helmet title={seo.title}>
                            <html lang={siteLanguage} />
                            <meta
                                name="description"
                                content={seo.description}
                            />
                            <meta name="image" content={seo.image} />
                            {!article && (
                                <script type="application/ld+json">
                                    {JSON.stringify(schemaOrgWebPage)}
                                </script>
                            )}
                            {article && (
                                <script type="application/ld+json">
                                    {JSON.stringify(schemaArticle)}
                                </script>
                            )}
                            <script type="application/ld+json">
                                {JSON.stringify(breadcrumb)}
                            </script>
                        </Helmet>
                    </>
                );
            }}
        />
    );
};

export default Metadata;

const query = graphql`
    query SEO {
        site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
                siteUrl
                defaultTitle: title
                defaultDescription: description
                defaultBanner: banner
                headline
                siteLanguage
                ogLanguage
                author
            }
        }
    }
`;
