import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import { Affix } from "antd";
import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import tw from "twin.macro";
import { Responsive } from "../utils/Responsive";
import { theme } from "../utils/theme";
import { Arrow } from "./icons/Arrow";
import { Menu } from "./Menu";
import { useBookAppointment } from "./Menu/hooks/useBookAppointment";
import { SubscribeForm } from "./Menu/SubscribeForm";
import { Space } from "./Space";
import { A, Body, FinePrint, Navlink, Text } from "./Typography";
import { RichText } from "./RichText";

const mobileRoutes = [
    { name: "Made to Measure", path: "/made-to-measure" },
    { name: "About Us", path: "/about" },
    { name: "Terms And Conditions", path: "/terms-and-conditions" },
];

const socials = [
    {
        name: "Facebook",
        mobileName: "FB",
        path: "https://www.facebook.com/OpenWeaves",
    },
    {
        name: "Instagram",
        mobileName: "IG",
        path: "https://www.instagram.com/openweaves",
    },
];

const Footer = () => {
    return (
        <StyledFooter>
            <AffixedMenu />
            <Responsive sm={<MobileContent />} md={<Content />} />
        </StyledFooter>
    );
};

export { Footer };

const AffixedMenu = () => {
    return (
        <div
            tw="grid"
            style={{
                gridTemplateRows: "1fr auto",
            }}
        >
            <div />
            <Affix offsetBottom={0}>
                <Menu />
            </Affix>
        </div>
    );
};

const MobileContent = () => {
    const { pathname } = useLocation();
    const { openBookAppointment } = useBookAppointment();

    return (
        <ContentWrapper bordered={pathname.includes("discover")}>
            <MobileStyledContent>
                <Body tw="mr-2">
                    <FooterContent />
                </Body>
                <div tw="grid gap-2" style={{ gridTemplateRows: "1fr auto" }}>
                    <div tw="grid" style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <Space.V size="xs">
                            <A
                                as="button"
                                onClick={() => openBookAppointment()}
                                style={{ textAlign: "initial" }}
                            >
                                Book an Appointment
                            </A>
                            {mobileRoutes.map((route) => (
                                <Link key={route.path} to={route.path}>
                                    <A as="span">{route.name}</A>
                                </Link>
                            ))}
                        </Space.V>

                        <div
                            tw="grid "
                            style={{ gridTemplateRows: "auto auto" }}
                        >
                            <div tw="mb-4">
                                <SubscribeForm />
                            </div>
                            <Space.H>
                                {socials.map((social) => (
                                    <A
                                        key={social.path}
                                        href={social.path}
                                        target="_blank"
                                    >
                                        {social.mobileName}
                                        <br />
                                    </A>
                                ))}
                            </Space.H>
                        </div>
                    </div>
                    <Space.H tw="items-end justify-between">
                        <FinePrint tw="pb-2">© 2021 Open Weaves</FinePrint>

                        <Navlink
                            onClick={() => scrollTop()}
                            tw="w-14 h-14 pb-2"
                        >
                            <div style={{ transform: "rotate(-90deg)" }}>
                                <Arrow />
                            </div>
                        </Navlink>
                    </Space.H>
                </div>
            </MobileStyledContent>
        </ContentWrapper>
    );
};

const Content = () => {
    const { pathname } = useLocation();
    const { openBookAppointment } = useBookAppointment();

    return (
        <ContentWrapper bordered={pathname.includes("discover")}>
            <div tw="grid" style={{ gridTemplateColumns: "1fr 1fr" }}>
                <div tw="grid" style={{ gridTemplateRows: "1fr 1fr" }}>
                    <Body tw="mr-16">
                        <FooterContent />
                    </Body>
                    <div tw="grid" style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <Space.V size="xs">
                            <MenuLink
                                onClick={() => openBookAppointment()}
                                text="Book an Appointment"
                            />
                            <MenuLink
                                to="/terms-and-conditions"
                                text="Terms and Conditions"
                            />
                            <MenuLink
                                to="/made-to-measure"
                                text="Made to Measure"
                            />
                            <MenuLink to="/about" text="About Us" />
                        </Space.V>
                    </div>
                </div>

                <div tw="grid" style={{ gridTemplateRows: "1fr 1fr" }}>
                    <div tw="grid" style={{ gridTemplateColumns: "1fr auto" }}>
                        <Space.V size="none">
                            <Text>55-12 Collins Street</Text>
                            <Text>Melbourne Australia 3000</Text>
                            <A
                                as="a"
                                href="mailto:hello@openweaves.com"
                                target="_blank"
                            >
                                hello@openweaves.com
                            </A>
                        </Space.V>
                        <Space.V size="xs">
                            {socials.map((social) => (
                                <A
                                    key={social.path}
                                    href={social.path}
                                    target="_blank"
                                >
                                    {social.name}
                                </A>
                            ))}
                        </Space.V>
                    </div>
                    <SubscribeForm />
                </div>
            </div>
            <div
                tw="grid items-center"
                style={{ gridTemplateColumns: "1fr auto" }}
            >
                <FinePrint>© 2021 Open Weaves</FinePrint>
                <Text onClick={() => scrollTop()} as="button">
                    Back to Top
                </Text>
            </div>
        </ContentWrapper>
    );
};

interface MenuLinkProps {
    onClick?: () => void;
    to?: string;
    text: string;
}

const MenuLink: React.FC<MenuLinkProps> = ({ onClick, to, text }) => {
    if (onClick)
        return (
            <A as="button" onClick={onClick}>
                {text}
            </A>
        );
    if (to)
        return (
            <Link to={to}>
                <A as="span">{text}</A>
            </Link>
        );
    return null;
};

const FooterContent = () => {
    return (
        <StaticQuery
            query={graphql`
                query FooterContentQuery {
                    contentfulFooter {
                        description {
                            raw
                        }
                    }
                }
            `}
            render={({ contentfulFooter: { description } }) => (
                <RichText>{description}</RichText>
            )}
        />
    );
};

const StyledFooter = styled.div`
    ${tw`relative grid`};
    height: 100vh;
    grid-template-rows: 1fr 1fr;
`;

const ContentWrapper = styled.div<{ bordered?: boolean }>`
    ${tw`relative grid py-3 2xl:py-6 px-5 2xl:px-8 h-full`};
    /* ${({ bordered }) => bordered && tw`border-t border-gray-600`}; */
    background: ${theme.colors.pistacio};
    grid-template-rows: 1fr auto;
`;

const MobileStyledContent = styled.div`
    ${tw`grid`};
    max-height: 50vh;
    grid-template-rows: 1fr 1fr;
`;

const scrollTop = () => {
    if (typeof window !== `undefined`) {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
};
