import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { whiteUiState } from "../../state/atoms";
import { Responsive } from "../../utils/Responsive";
import { Navlink } from "../Typography";

const Menu = () => {
    const isWhiteUi = useRecoilValue(whiteUiState);

    const scrollBottom = () => {
        if (typeof window !== `undefined`) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    return (
        <Nav {...{ isWhiteUi }}>
            <Navlink onClick={() => scrollBottom()} tw="pointer-events-auto">
                Info
            </Navlink>
            <div tw="grid" style={{ gridTemplateColumns: "auto 1fr auto" }}>
                <Responsive
                    sm={<div />}
                    md={
                        <Navlink as={Link} to="/shop" tw="pointer-events-auto">
                            Shop
                        </Navlink>
                    }
                />
                <div />
                <Navlink as={Link} to="/discover" tw="pointer-events-auto">
                    Discover
                </Navlink>
            </div>
        </Nav>
    );
};

export { Menu };

const Nav = styled.div<{ isWhiteUi?: boolean }>`
    ${tw`w-full grid py-3 2xl:py-6 px-5 2xl:px-8 pointer-events-none transition duration-150 ease`};

    & * {
        color: ${({ isWhiteUi }) => isWhiteUi && "white"};
    }

    grid-template-columns: 1fr 1fr;
`;
