import React, { useState } from "react";
import { Formik, Field, Form, FormikProps, ErrorMessage } from "formik";
import { Responsive } from "../../utils/Responsive";
import { Space } from "../Space";
import { A, Body, Input, Text } from "../Typography";
import * as Yup from "yup";

interface SubscribeFormValues {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Please enter a valid Email Address.")
        .required("Your Email is required."),
});

const initialValues = {
    email: "",
};

const SubscribeForm = () => {
    const [isCompleted, setCompleted] = useState<boolean>(false);

    const handleSubmit = async ({ email }: SubscribeFormValues) => {
        try {
            const res = await fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "subscription", ...{ email } }),
            });

            if (res.ok) {
                setCompleted(true);
            } else
                alert(
                    `${res.status}: ${res.statusText}. Please contact j@openweaves.com directly!`
                );
        } catch (error) {
            alert(error.message);
        }
    };

    if (isCompleted) {
        return <Body>Submitted, Thank you!</Body>;
    }

    return (
        <Formik
            name="subscription"
            method="POST"
            data-netlify="true"
            {...{ initialValues, validationSchema }}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange={false}
        >
            {({}: FormikProps<SubscribeFormValues>) => {
                return (
                    <Form>
                        <Responsive
                            sm={
                                <Space.V
                                    size="none"
                                    style={{ width: "fit-content" }}
                                >
                                    <Text>Open Communications</Text>
                                    <Field
                                        name="email"
                                        type="email"
                                        placeholder="Email Address"
                                        component={Input}
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component={Text}
                                    />
                                    <A as="button" type="submit">
                                        Subscribe
                                    </A>
                                </Space.V>
                            }
                            md={
                                <Space.V size="sm">
                                    <Space.V
                                        size="none"
                                        style={{ width: "fit-content" }}
                                    >
                                        <Text>Open Communications—</Text>
                                        <Text style={{ textTransform: "none" }}>
                                            For regular updates and news on our
                                            collection:
                                        </Text>
                                        <Field
                                            name="email"
                                            type="email"
                                            placeholder="Email Address"
                                            component={Input}
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component={Text}
                                        />
                                    </Space.V>
                                    <A as="button" type="submit">
                                        Subscribe
                                    </A>
                                </Space.V>
                            }
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export { SubscribeForm };

const encode = (data) => {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
};
