import React from "react";

const Arrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 225.9 225.9"
            stroke="currentColor"
        >
            <defs></defs>
            <g id="BRANDMARKS">
                <polygon
                    className="cls-1"
                    points="165.43 112.95 154.64 123.74 128.59 149.79 123.32 144.52 151.7 116.14 60.47 116.14 60.47 108.69 97.32 108.69 131.79 108.68 150.61 108.68 137.3 95.37 123.32 81.38 128.59 76.11 154.64 102.16 165.43 112.95"
                />
            </g>
        </svg>
    );
};

export { Arrow };
