import styled from "@emotion/styled";
import { globalHistory } from "@reach/router";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import tw from "twin.macro";
import { whiteUiState } from "../state/atoms";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { BookAppointment } from "./Menu/BookAppointment";

const Layout: React.FC = ({ children }) => {
    const setWhiteUi = useSetRecoilState(whiteUiState);

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === "PUSH") setWhiteUi(false);
        });
    }, [setWhiteUi]);

    useEffect(() => {
        if (typeof window !== `undefined`) {
            window.scrollTo({
                top: 0,
            });
        }
    }, []);

    return (
        <>
            <BookAppointment />

            <div
                style={{
                    scrollSnapAlign: "start",
                }}
            >
                <Header />
                <StyledLayout>
                    <div>{children}</div>
                </StyledLayout>
            </div>

            <div
                tw="relative z-20"
                style={{
                    scrollSnapAlign: "start",
                }}
            >
                <Footer />
            </div>
        </>
    );
};

export { Layout };

const StyledLayout = styled.div`
    ${tw`relative grid w-full min-h-screen`};
`;
